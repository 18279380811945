"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicModel = exports.PublicModelTypeName = void 0;
exports.PublicModelTypeName = "mesh::clientPublicProfileView/PublicModel";
class PublicModel {
    constructor(publicModel) {
        this["@type"] = exports.PublicModelTypeName;
        this.clientName = "";
        this.description = "";
        this.website = "";
        this.twitter = "";
        this.clientProfilePicture = "";
        if (!publicModel) {
            return;
        }
        this.clientName = publicModel.clientName;
        this.description = publicModel.description;
        this.website = publicModel.website;
        this.twitter = publicModel.twitter;
        this.clientProfilePicture = publicModel.clientProfilePicture;
    }
}
exports.PublicModel = PublicModel;
