"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Reader = void 0;
const Model_1 = require("./Model");
const jsonrpc_1 = require("../../jsonrpc");
const ReaderServiceProviderName = "clientPublicProfileView-Reader";
class Reader {
    constructor(url) {
        this.url = url;
    }
    PublicReadOne(request) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield (0, jsonrpc_1.jsonRPCRequest)({
                url: this.url,
                method: `${ReaderServiceProviderName}.PublicReadOne`,
                request,
            });
            return {
                publicModel: new Model_1.PublicModel(response.publicModel),
            };
        });
    }
}
exports.Reader = Reader;
