import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
  CircularProgress,
  Button,
  ButtonGroup,
} from "@mui/material";
import backgroundCroppedDark from "./assets/images/background/backgroundCropped.jpeg";
import { PublicModel } from "@mesh/common-js/dist/views/clientPublicProfileView";
import { useAPIContext } from "context/API";
import { useConfigContext } from "./context/Config";
import { Environment } from "@mesh/common-js/dist/environment/environment_pb";
import { environmentToString } from "@mesh/common-js/dist/environment";
import { Helmet } from "react-helmet-async";

export const App: () => JSX.Element = () => {
  const { environment } = useConfigContext();
  const {
    views: { clientPublicProfileViewReader },
  } = useAPIContext();

  // load the public model on start up
  const [publicModel, setPublicModel] = useState<PublicModel | undefined>(
    undefined,
  );
  const [shortName, setShortName] = useState("");
  useEffect(() => {
    // determine short name to use in different ways depending on the environment
    let shortName: string = "";
    switch (environment) {
      case Environment.LOCAL_ENVIRONMENT:
        {
          const rawShortName = new URLSearchParams(
            new URL(window.location.href).search,
          ).get("shortName");
          if (!rawShortName) {
            return;
          }
          shortName = rawShortName;
        }
        break;

      default: {
        // get short name out of location
        const hostname = window.location.hostname;
        const subdomains = hostname.split(".");
        if (!subdomains.length) {
          return;
        }
        const firstBit = subdomains[0];

        switch (environment) {
          case Environment.DEVELOPMENT_ENVIRONMENT:
          case Environment.TESTING_ENVIRONMENT:
          case Environment.STAGING_ENVIRONMENT:
            shortName = firstBit.replaceAll(
              `${environmentToString(environment).toLowerCase()}-`,
              "",
            );
            break;

          case Environment.PRODUCTION_ENVIRONMENT:
            shortName = firstBit;
            break;

          default:
            return;
        }
      }
    }
    console.debug("using issuer short name:", shortName);
    setShortName(shortName);

    (async () => {
      try {
        setPublicModel(
          (
            await clientPublicProfileViewReader.PublicReadOne({
              identifier: {
                type: "ShortName",
                shortName: shortName.toUpperCase(),
              },
            })
          ).publicModel,
        );
      } catch (e) {
        console.error("error getting issuer public profile", e);
      }
    })();
  }, [environment]);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${backgroundCroppedDark})`,
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      {publicModel ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: window.innerHeight,
          }}
        >
          <Helmet>
            <title>{publicModel.clientName} on Mesh</title>
            <meta charSet="utf-8" />
            <meta
              name="description"
              content={`Mesh TOML File Hosting Service for ${publicModel.clientName}`}
            />
          </Helmet>
          <Card>
            <CardContent
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyItems: "center",
                gap: theme.spacing(4),
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
              })}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                <Typography variant="h1">{publicModel.clientName}</Typography>
                <Typography variant="h4">On Mesh</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyItems: "center",
                }}
              >
                <Box
                  component={"img"}
                  maxHeight={155}
                  maxWidth={206}
                  src={publicModel.clientProfilePicture}
                />
                <ButtonGroup
                  variant="contained"
                  aria-label="Basic button group"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      window.open(publicModel.website, "_blank");
                    }}
                  >
                    Go To {publicModel.clientName}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      let url: string = "";
                      switch (environment) {
                        case Environment.LOCAL_ENVIRONMENT:
                          url = `http://localhost:8084/.well-known/stellar.toml?shortName=${shortName}`;
                          break;

                        case Environment.DEVELOPMENT_ENVIRONMENT:
                        case Environment.TESTING_ENVIRONMENT:
                        case Environment.STAGING_ENVIRONMENT:
                        case Environment.PRODUCTION_ENVIRONMENT:
                          url = `${window.location.origin}/.well-known/stellar.toml`;
                          break;

                        default:
                          break;
                      }

                      window.open(url, "_blank");
                    }}
                  >
                    View TOML
                  </Button>
                </ButtonGroup>
              </Box>

              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyItems: "center",
                  gap: theme.spacing(3),
                })}
              >
                <Box
                  component={"img"}
                  maxHeight={155}
                  maxWidth={206}
                  src={
                    "https://www.mesh.trade/wp-content/uploads/2021/07/Mesh-Branding.png"
                  }
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    window.open("https://mesh.trade", "_blank");
                  }}
                >
                  Go To Mesh
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: window.innerHeight,
          }}
        >
          <Card>
            <CardContent
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyItems: "center",
                gap: theme.spacing(2),
              })}
            >
              <CircularProgress size={70} />
              <Typography
                variant="h5"
                color="textSecondary"
                children="Getting things ready for you..."
              />
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};
