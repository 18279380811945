import React, { useContext, useMemo } from "react";
import { useConfigContext } from "../Config";
import { Reader } from "@mesh/common-js/dist/views/clientPublicProfileView";

export type API = {
  views: {
    clientPublicProfileViewReader: Reader;
  };
};

const defaultContext: API = {
  views: {
    clientPublicProfileViewReader: new Reader(""),
  },
};

export const APIContext = React.createContext<API>(defaultContext);

export const APIProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { apiURL } = useConfigContext();
  const api = useMemo<API>(
    () => ({
      views: {
        clientPublicProfileViewReader: new Reader(apiURL),
      },
    }),
    [apiURL],
  );

  return <APIContext.Provider value={api}>{children}</APIContext.Provider>;
};

export const useAPIContext = () => useContext(APIContext);
