import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { reportWebVitals } from "./reportWebVitals";
import { ConfigProvider } from "./context/Config";
import { HelmetContext } from "./context/helmet/context";
import { MuiThemeContext } from "./context/Theme/context";
import { APIProvider } from "./context/API";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <HelmetContext>
      <MuiThemeContext>
        <ConfigProvider>
          <APIProvider>
            <App />
          </APIProvider>
        </ConfigProvider>
      </MuiThemeContext>
    </HelmetContext>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
