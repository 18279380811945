"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.jsonRPCRequest = jsonRPCRequest;
function jsonRPCRequest(_a, opts_1) {
    return __awaiter(this, arguments, void 0, function* ({ url, method, request }, opts) {
        const header = new Headers({
            "Content-Type": "application/json",
        });
        // add any additional headers
        if (opts === null || opts === void 0 ? void 0 : opts.additionalHeaders) {
            opts.additionalHeaders.forEach((value, name) => header.append(name, value));
        }
        const body = {
            jsonrpc: "2.0",
            method,
            params: request,
            id: 1234,
        };
        console.debug(`API Request: ${body.method} -->\n`, body.params);
        if (opts === null || opts === void 0 ? void 0 : opts.verbose) {
            try {
                console.debug("\n", JSON.parse(JSON.stringify(body.params)));
                console.debug("\n", JSON.stringify(body.params));
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }
            catch (e) {
                console.error("error parsing stringified body.params[0]");
                throw e;
            }
        }
        // perform request
        const responseObject = yield fetch(url, {
            method: "POST",
            headers: header,
            mode: "cors",
            body: JSON.stringify(body),
            credentials: (opts === null || opts === void 0 ? void 0 : opts.excludeCredentials) ? undefined : "include",
            signal: opts === null || opts === void 0 ? void 0 : opts.signal,
        });
        // check for unauthorised HTTP response
        if (responseObject.status !== 200) {
            throw new Error(`error performing request - got response ${responseObject.status}`);
        }
        // try and parse response object as JSON
        const responseObjectJson = yield responseObject.json();
        // parsing response object as JSON has succeeded
        // if the response object has a result field then the request was successful
        // (i.e. no error was returned from JSON-RPC adaptor)
        if (responseObjectJson.result) {
            console.debug(`API Response Success: ${body.method}-->\n`, responseObjectJson.result);
            return responseObjectJson.result;
        }
        // otherwise an error occurred
        // (i.e. an error was returned from JSON-RPC adaptor)
        throw responseObjectJson.error;
    });
}
